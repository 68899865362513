import React, { createContext, useState, useEffect } from 'react';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  signOut, 
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset
} from 'firebase/auth';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc
} from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        fetchUserData(firebaseUser.uid);
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const data = userDoc.data();
        const maxExams = getMaxExams(data.plan);
        setUser({ 
          id: userDoc.id, 
          ...data, 
          savedExams: Array.isArray(data.savedExams) ? data.savedExams.map(exam => ({
            ...exam,
            attempts: exam.attempts ? exam.attempts.map(attempt => ({
              ...attempt,
              answers: parseAnswers(attempt.answers || [])
            })) : []
          })) : [],
          plan: data.plan || 'free',
          planStartDate: data.planStartDate || null,
          planEndDate: data.planEndDate || null,
          stripeCustomerId: data.stripeCustomerId || null,
          stripeSubscriptionId: data.stripeSubscriptionId || null,
          maxExams: maxExams,
          remainingExams: maxExams - (data.savedExams ? data.savedExams.length : 0)
        });
      } else {
        const newUser = {
          id: userId,
          email: auth.currentUser.email,
          name: auth.currentUser.displayName,
          emailVerified: auth.currentUser.emailVerified,
          savedExams: [],
          plan: 'free',
          planStartDate: null,
          planEndDate: null,
          stripeCustomerId: null,
          stripeSubscriptionId: null,
          maxExams: 3,
          remainingExams: 3
        };
        await setDoc(doc(db, 'users', userId), newUser);
        setUser(newUser);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const refreshUserData = async () => {
    if (user) {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.id));
        if (userDoc.exists()) {
          const data = userDoc.data();
          const maxExams = getMaxExams(data.plan);
          setUser(prevUser => ({ 
            ...prevUser,
            ...data,
            savedExams: Array.isArray(data.savedExams) ? data.savedExams.map(exam => ({
              ...exam,
              attempts: exam.attempts ? exam.attempts.map(attempt => ({
                ...attempt,
                answers: parseAnswers(attempt.answers || [])
              })) : []
            })) : [],
            maxExams: maxExams,
            remainingExams: maxExams - (data.savedExams ? data.savedExams.length : 0)
          }));
        }
      } catch (error) {
        console.error("Error refreshing user data:", error);
      }
    }
  };

  const getMaxExams = (plan) => {
    switch (plan) {
      case 'pro':
        return 100;
      case 'enterprise':
        return 500;
      default:
        return 3;
    }
  };

  const loginWithEmailPassword = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        await sendEmailVerification(user);
        throw new Error("Please verify your email address. A new verification email has been sent.");
      }

      return user;
    } catch (error) {
      console.error("Error during email/password login:", error);
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      return result.user;
    } catch (error) {
      console.error("Error during Google login:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error during logout:", error);
      throw error;
    }
  };

  const signup = async (email, password, name) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName: name });
      
      await sendEmailVerification(user);

      await setDoc(doc(db, 'users', user.uid), {
        email: email,
        name: name,
        emailVerified: false,
        savedExams: [],
        plan: 'free',
        planStartDate: null,
        planEndDate: null,
        stripeCustomerId: null,
        stripeSubscriptionId: null,
        maxExams: 3,
        remainingExams: 3
      });

      setUser({
        id: user.uid,
        email: email,
        name: name,
        emailVerified: false,
        savedExams: [],
        plan: 'free',
        planStartDate: null,
        planEndDate: null,
        stripeCustomerId: null,
        stripeSubscriptionId: null,
        maxExams: 3,
        remainingExams: 3
      });

      return user;
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
  };

  const forgotPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      console.error("Error sending password reset email:", error);
      throw error;
    }
  };

  const resetPassword = async (oobCode, newPassword) => {
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      return true;
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  };

  const checkEmailVerification = async () => {
    if (auth.currentUser) {
      await auth.currentUser.reload();
      const isVerified = auth.currentUser.emailVerified;
      
      if (isVerified) {
        const userRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userRef, { emailVerified: true });
        setUser(prevUser => ({ ...prevUser, emailVerified: true }));
      }

      return isVerified;
    }
    return false;
  };

  const flattenAttempt = (attempt) => {
    return {
      ...attempt,
      answers: attempt.answers ? attempt.answers.map(JSON.stringify) : []
    };
  };

  const parseAnswers = (answers) => {
    return answers.map(answer => {
      try {
        return JSON.parse(answer);
      } catch (e) {
        console.error("Error parsing answer:", e);
        return answer;
      }
    });
  };

  const addExam = async (newExam) => {
    if (user) {
      try {
        if (user.savedExams.length >= user.maxExams) {
          throw new Error(`You have reached the maximum number of exams (${user.maxExams}) for your plan.`);
        }
  
        const userRef = doc(db, 'users', user.id);
        const keywordsArray = Array.isArray(newExam.keywords) ? newExam.keywords : (newExam.keywords ? [newExam.keywords] : []);
  
        const existingExam = user.savedExams.find(exam => 
          exam.name === newExam.name || 
          (Array.isArray(exam.keywords) && exam.keywords.some(keyword => keywordsArray.includes(keyword)))
        );
  
        if (existingExam) {
          return updateExamScore(existingExam.name, {
            id: Date.now().toString(),
            score: null,
            date: new Date().toISOString(),
            answers: [],
            completed: false
          });
        } else {
          const examWithScore = {
            ...newExam,
            lastScore: null,
            attempts: [],
            keywords: keywordsArray
          };
          const updatedSavedExams = [...user.savedExams, examWithScore];
          await updateDoc(userRef, {
            savedExams: updatedSavedExams.map(exam => ({
              ...exam,
              attempts: (exam.attempts || []).map(flattenAttempt)
            }))
          });
          setUser(prevUser => ({
            ...prevUser,
            savedExams: updatedSavedExams,
            remainingExams: prevUser.maxExams - updatedSavedExams.length
          }));
          return examWithScore;
        }
      } catch (error) {
        console.error("Error adding exam:", error);
        if (error.code === 'permission-denied') {
          throw new Error('You do not have permission to add exams. Please check your account status.');
        } else if (error.code === 'resource-exhausted') {
          throw new Error('You have reached the limit for adding exams. Please upgrade your plan or delete some exams.');
        } else {
          throw error;
        }
      }
    } else {
      throw new Error('User not authenticated. Please log in to add exams.');
    }
  };

  const deleteExam = async (examToDelete) => {
    if (user) {
      try {
        const userRef = doc(db, 'users', user.id);
        const updatedSavedExams = user.savedExams.filter(exam => exam.name !== examToDelete.name);
        await updateDoc(userRef, {
          savedExams: updatedSavedExams.map(exam => ({
            ...exam,
            attempts: (exam.attempts || []).map(flattenAttempt)
          }))
        });
        setUser(prevUser => ({
          ...prevUser,
          savedExams: updatedSavedExams,
          remainingExams: prevUser.maxExams - updatedSavedExams.length
        }));
      } catch (error) {
        console.error("Error deleting exam:", error);
        throw error;
      }
    }
  };

  const updateExamScore = async (examName, attempt) => {
    if (user) {
      try {
        const userRef = doc(db, 'users', user.id);
        const updatedExams = user.savedExams.map(exam => {
          if (exam.name === examName) {
            if (attempt.completed) {
              return {
                ...exam,
                attempts: [...(exam.attempts || []), flattenAttempt(attempt)],
                lastScore: attempt.score
              };
            }
            return exam;
          }
          return exam;
        });

        await updateDoc(userRef, { 
          savedExams: updatedExams.map(exam => ({
            ...exam,
            attempts: (exam.attempts || []).map(flattenAttempt)
          }))
        });

        setUser(prevUser => ({
          ...prevUser,
          savedExams: updatedExams.map(exam => ({
            ...exam,
            attempts: (exam.attempts || []).map(attempt => ({
              ...attempt,
              answers: parseAnswers(attempt.answers || [])
            }))
          }))
        }));
      } catch (error) {
        console.error("Error updating exam score:", error);
        throw error;
      }
    }
  };

  const updateUserPlan = async (planDetails) => {
    if (user) {
      try {
        const userRef = doc(db, 'users', user.id);
        const maxExams = getMaxExams(planDetails.plan);
        await updateDoc(userRef, {
          plan: planDetails.plan,
          planStartDate: planDetails.startDate,
          planEndDate: planDetails.endDate,
          stripeCustomerId: planDetails.stripeCustomerId,
          stripeSubscriptionId: planDetails.stripeSubscriptionId,
          maxExams: maxExams
        });
        setUser(prevUser => ({
          ...prevUser,
          ...planDetails,
          maxExams: maxExams,
          remainingExams: maxExams - prevUser.savedExams.length
        }));
      } catch (error) {
        console.error("Error updating user plan:", error);
        throw error;
      }
    }
  };

  const updateExamInUserContext = (updatedExam) => {
    setUser(prevUser => ({
      ...prevUser,
      savedExams: prevUser.savedExams.map(exam => 
        exam.name === updatedExam.name ? {
          ...updatedExam,
          attempts: (updatedExam.attempts || []).map(attempt => ({
            ...attempt,
            answers: parseAnswers(attempt.answers || [])
          }))
        } : exam
      ),
    }));
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      loginWithEmailPassword,
      loginWithGoogle,
      logout, 
      signup,
      forgotPassword,
      resetPassword,
      checkEmailVerification,
      addExam, 
      deleteExam,
      updateExamScore,
      updateUserPlan,
      updateExamInUserContext,
      refreshUserData,
      loading 
    }}>
      {children}
    </UserContext.Provider>
  );
};
//
export default UserProvider;
